import {Notification} from '../../modules/notification.js';
import {Spinner} from '../../modules/spinner.js';
import {getHttpClient} from '../../modules/httpClient.js';

/**
 * @param {Event} submitEvent
 */
async function submitByAjaxWithContextData(submitEvent) {
    submitEvent.preventDefault();

    const form = submitEvent.target;
    const url = form.getAttribute('action');
    const dataToSend = new FormData(form);

    const spinner = new Spinner(form);
    spinner.start();

    const response = await getHttpClient()
        .post(url, dataToSend)
        .catch(() => {
            const errorMessage = 'Failed to submit the error report';
            Notification.error(errorMessage);
        });

    spinner.stop();

    if (response) {
        document.querySelectorAll('.js-messageState, .js-toggleAfterSubmit').forEach((element) => {
            element.classList.toggle('hidden');
        });
    }
}

/**
 * Init page, register event handlers
 */
function initPage() {
    const errorForm = document.querySelector('[name="error-report-form"]');
    if (errorForm) errorForm.addEventListener('submit', submitByAjaxWithContextData);
}

// init page when the document is ready
document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', initPage) : initPage();
